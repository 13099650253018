import { formatDateFromString } from "../utils/formaters";

interface CardWithDateAndAmountProps {
    date: string;
    amount: number;
    label: string;
    classname?: string;
    currency_symbol: string;
    currency_code?: string;
}

export function CardWithDateAndAmount({
    date,
    amount,
    label,
    classname = "",
    currency_symbol,
    currency_code
}: CardWithDateAndAmountProps) {
    const displayDate =
        amount === 0 || amount === -1 ? "--/--/----" : formatDateFromString(date);
    const displayAmount = amount === -1 ? "----" : amount.toLocaleString().split(".")[0];
    
    const fullCurrency =
    currency_code && currency_symbol
      ? currency_code + " " + currency_symbol + " "
      : currency_symbol
      ? currency_symbol + " "
      : "";

    return (
        <div className={`bg-white px-6 py-4 rounded-xl flex flex-col w-full ${classname}`}>
            <div className="flex flex-row justify-between mb-2 items-center">
                <span>{label}</span>
                <span>{displayDate}</span>
            </div>
            <b className="text-4xl mt-2">
                <span className="color-gray-5">{fullCurrency}</span>
                {displayAmount}
            </b>
        </div>
    );
}
